let URL = "https://djwy-api.js56918.com";
let BASE_API;
if (process.env.NODE_ENV === "development") {
  //开发环境下的代理地址，
  BASE_API = "https://djwy-api.js56918.com";
} else if (process.env.NODE_ENV === "production") {
  //生产环境下的地址
  BASE_API = URL;
}
export { BASE_API };
