import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import axios from "./utils/request.js";
import echarts from 'echarts' 
import iView from 'iview'
import 'iview/dist/styles/iview.css'
Vue.prototype.$ajax = axios;

import "normalize.css/normalize.css";
import "./assets/css/reset.css";

import "./assets/scss/element-variables.scss";
import "./assets/scss/index.scss";

Vue.use(ElementUI);
Vue.use(iView);
Vue.use(echarts);

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
