import { login, logout, getInfo } from "@/api/login";
import { getToken, setToken, removeToken } from "@/utils/auth";
const user = {
  state: {
    token: getToken()
    // userInfo: "",
    // username: "",
    // token: localStorage.getItem("token")
  },
  mutations: {
    // SET_USERINFO: (state, userInfo) => {
    //   state.userInfo = userInfo;
    // },
    // SET_USERNAME: (state, username) => 
    //   state.username = username;
    // }
  },
  actions: {
    //登录
    Login({ commit }, userInfo) {
      const username = userInfo.username.trim();
      console.log(userInfo)
      return new Promise((resolve, reject) => {
        login(username, userInfo.password, userInfo.rememberMe).then(res => {
            const data = res.data;
            setToken("Bearer " + res.id_token);
            commit("SET_TOKEN", res.id_token);
            resolve(res)
         }).catch(error => {
             reject(error)
          })
      })
    },
    
    //获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
            let userInfo = response.user
            sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
            commit("SET_NAME", userInfo.nickname);
            commit("SET_AVATAR", userInfo.photo);
            
            resolve(response)
          })
          .catch(error => {
              reject(error)
          })
      });
    },
    
    // 退出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
      	commit("SET_TOKEN", "")
            removeToken();
            resolve();
//      logout(state.token)
//      .then(() => {
//      })
//      .catch(error => {
//        reject(error);
//      })
      })
    }
  }
};
export default user;
