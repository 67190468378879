import request from "@/utils/request";
export function login(username, password, rememberMe) {
  return request({
    url: "/oilCard/api/authenticate",
    method: "post",
    data: {
      username,
      password,
      rememberMe
    }
  })
}

export function getInfo() {
  return request.get("/oilCard/api/account", {});
}

export function logout() {
	return request({
	    url: "/oilCard/user/logout",
	    method: "post"
	});
}
