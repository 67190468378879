const state = {
  //corpId: "",
  company: "大件无忧油卡管理系统",
  //顶部导航
  navbarList: [
// {
//   title: "运输管理",
//   name: "transportation",
//   path: "/"
// },
// {
//   title: "财务管理",
//   name: "finance",
//   path: "/"
// },
// {
//   title: "车辆管理",
//   name: "vehicle",
//   path: "/"
// },
// {
//   path: "/company-manage",
//   name: "CompanyManage",
//   title: "企业管理"
// },
// {
//    path: "/burse-manage",
//    name: "BurseManage",
//    title: "油卡管理"
// }
  ],
  navbarActiveName: "",
  token:'',
  username:'',
  photo:''
  
};
export default state;
